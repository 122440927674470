<template>
	<router-view :pevents="pevents" />
</template>

<script>
	export default {
		name: "Root",
		props: ['pevents']
	}
</script>

<style scoped>

</style>